import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { Button } from "react-bootstrap";
import Hero from "../components/hero";
import PricingCard from "../components/pricing-card";
import PricingCardLarge from "../components/pricing-card-large";

const PackagesPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "17.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Packages and Prices";
	const description =
		"How Much Does Business or Executive Coaching Cost? Here are the available coaching packages with 1 month to 12 month options";
	const heroImg = data.heroImg.childImageSharp.fluid;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Packages and Prices",
				item: {
					url: `${siteUrl}/packages`,
					id: `${siteUrl}/packages`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/packages`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Prices and Packages for Jason Cornes Business Coach",
						},
					],
				}}
			/>
			<Hero
				backgroundImage={heroImg}
				title="How Much Does Business or Executive Coaching Cost?"
				isDarken={true}
			/>
			{/* <section className="py-5">
        <Container>
          
          <Row>
            <Col>
              <p>
                So, you’re thinking of engaging my services. Congratulations,
                that’s a fantastic first step on the road towards your success.
                But how much does Business or Executive Coaching cost? What is
                the investment? Can you afford it? Perhaps we should think about
                whether you can afford NOT to.
              </p>
              <p>
                I offer a range of bespoke Business and Executive Coaching
                solutions and packages. Whether you’re facing a challenge in
                your business, organisation or career, have just started in
                business, feeling stuck, reached a crossroads, or would
                appreciate a different perspective, I can help.
              </p>
              <p>
                Business Coaching, for example, secures you a professional
                advantage, while preventing you from making obvious mistakes. In
                brief, you could be more successful within a shorter time frame.
              </p>
              <p>
                There are no shortcuts. There are, however, different or more
                efficient ways of doing things.
              </p>
              <p>
                Executive Coaching could help you better understand yourself and
                others. Benefit from an independent sounding board, see new
                possibilities, put together a strategic plan, find a new pathway
                for yourself, your organisation or your career, or become a
                better leader.
              </p>
            </Col>
          </Row>
        </Container>
      </section> */}
			{/* <section className="bg-primary text-white py-5">
        <Container>
          <Row>
            <Col>
              <h2>Important and Excellent News!</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Please be aware that my Business and Executive Coaching services
                are a business expense. This means that they are fully
                tax-deductible. So, don’t forget to include your coaching fees
                in your tax return. Now, that’s very good news, isn’t it?
              </p>
              <p>
                So, to answer your question: how much does Business or Executive
                Coaching cost? As you can see, less than you think.
              </p>
            </Col>
          </Row>
        </Container>
      </section> */}

			<section
				className="py-lg-7 pb-5 pt-7"
				style={{ backgroundColor: "#F5F5F5" }}
			>
				<Container>
					<Row>
						<Col>
							<h2 className="text-center text-primary pb-7">
								Business and executive coaching packages
							</h2>
						</Col>
					</Row>
					<Row className="justify-content-center">
						{" "}
						<PricingCard
							title={
								<span>
									Problem Buster Package{" "}
									<span className="text-white">of Business Coaching</span>
								</span>
							}
							subText="Let’s tackle a business challenge or opportunity head-on."
							price={
								<span>
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										UK £995
									</span>{" "}
									<span className="text-primary font-weight-bold">+ VAT</span>
									<br />
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										USA $1500
									</span>{" "}
								</span>
							}
							features={[
								"3 sessions (4 hours) + complimentary follow up session",

								"Targeted problem-solving to address your specific issue",

								"Explore a range of options including ‘outside the box’",
								"Develop a clear, actionable resolution or plan",

								"‘Homework’ and support between sessions as required",
								"A standalone option or continue to 6 or 12 Months Business & Executive Coaching",
							]}
						/>
						{/* <PricingCard
							title="3 Months Business and Executive Coaching"
							subText={["Let’s gain focus and get you on track to success!"]}
							price={
								<span>
									<br className="d-none d-md-block" />
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										£2085
									</span>{" "}
									/ 3 months
									<span className="d-block mt-2 mb-0 pb-0 font-weight-bold">
										Payment options available upon request.
									</span>
								</span>
							}
							features={[
								"Up to 7 sessions within 3 months",
								"Telephone and e-mail support between sessions",
								"Tailored to you and your business",
								"Referrals to my network of trusted specialists",
							]}
						/>{" "} */}
						<PricingCard
							title={
								<span>
									Business MOT/Health Check{" "}
									<span className="text-white">of Business Coaching</span>
								</span>
							}
							subText="Let’s explore opportunities for improvement and growth in your small business or practice."
							price={
								<span>
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										UK £995
									</span>{" "}
									<span className="text-primary font-weight-bold">+ VAT</span>
									<br />
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										USA $1500
									</span>{" "}
								</span>
							}
							features={[
								"3 sessions (4 hours) + complimentary follow up session",
								"A bespoke deep dive into your small business or practice",
								"Highlight improvements. Transform challenges into opportunities",
								<span>
									Create a list of action points and a
									<br className="d-none d-xl-block" /> timeline
								</span>,
								"‘Homework’ and support between sessions as required",
								"A standalone option or continue to 6 or 12 Months Business & Executive Coaching",
							]}
						/>{" "}
						<PricingCard
							title="6 or 12 Months Business and Executive Coaching"
							subText="Let's take bold steps together to transform your vision into reality."
							price={
								<span>
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										UK £3950
									</span>{" "}
									<span className="text-primary font-weight-bold">
										+ VAT for 6 months
									</span>
									<br />
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										USA $5500
									</span>{" "}
									<span className="text-primary font-weight-bold">
										for 6 months
									</span>
									<br />
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										UK £7250
									</span>{" "}
									<span className="text-primary font-weight-bold">
										+ VAT for 12 months
									</span>
									<br />
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										USA $10,000
									</span>{" "}
									<span className="text-primary font-weight-bold">
										for 12 months
									</span>
									<br />
									<span className="d-block mt-2 mb-0 pb-0 font-weight-bold">
										Payment options available
									</span>
								</span>
							}
							features={[
								"Choose from up to 12 sessions within 6 months or up to 24 sessions within 12 months",
								"In-depth bespoke business & executive coaching tailored to the unique needs of you and your business or practice",

								"Customised ‘homework’ and contact between sessions",
								"Referrals to my network of trusted specialists",
								"Holding you accountable to ensure you remain committed, motivated and focused",
							]}
						/>
					</Row>
				</Container>
			</section>

			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							* <h2>Option 1</h2> *
							<h2>
								Business MOT <span className="text-secondary">£495</span>
							</h2>
							<hr className="bg-secondary w-25 float-left" />
						</Col>
					</Row>
					<Row>
						<Col>
							<ul>
								<li>3 hours over 2 sessions</li>
								<li>In-depth look at your small business</li>
								<li>Assess where you are now and your vision</li>
								<li>
									Learn new or better ways to grow your business and achieve
									your goals
								</li>
								<li>
									Outline a plan or series of action points to move forward
								</li>
								<li>
									Decide the order that the action points should be carried out
								</li>
								<li>Referrals to my network of trusted specialists </li>
								<li>
									Standalone or continue to 3, 6 or 12 months of business
									coaching
								</li>
								<li>30 minute follow-up accountability coaching session</li>
							</ul>
						</Col>
					</Row>
					<Row className="text-left mt-3">
						<Col>
							<Button
								variant="outline-primary"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Book Your Discovery Meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2>Business Coaching</h2>
							<h3>
								3 months coaching <span className="text-secondary">£1485</span>
							</h3>
							<h3>
								6 months coaching for the price of 5{" "}
								<span className="text-secondary">£2475</span>
							</h3>
							<h3>
								12 months coaching for the price of 10{" "}
								<span className="text-secondary">£4950</span>
							</h3>
							<hr className="bg-secondary w-25 float-left" />
						</Col>
					</Row>
					<Row>
						<Col>
							<ul>
								<li>Unlimited Zoom or Face-to-Face sessions</li>
								<li>Telephone and E-mail support between sessions</li>
								<li>Tailored to you and your business</li>
							</ul>
						</Col>
					</Row>
					<Row className="text-left mt-3">
						<Col>
							<Button
								variant="outline-primary"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Book Your Discovery Meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
        <Container>
          <Row>
            <Col>
              <h2>Option 3</h2>
              <h3>
                3 Month Coaching Programme{" "}
                <span className="text-secondary">£1170</span>
              </h3>
              <hr className="bg-secondary w-25 float-left" />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                My customised 3-month Business or Executive Coaching programmes
                suit almost every small business owner or senior decision-maker,
                regardless of organisation or business type.
              </p>
              <p>
                The advantages of taking out a package are that you are making a
                commitment to work with me over a period of time to make
                positive change. You’ll benefit from contact and support between
                meetings and being held accountable for staying focused and
                carrying out agreed actions.
              </p>
              <p>
                Whether you have a trade or professional services business or
                you’re coming to me for executive coaching, my services will be
                tailored exactly to you. You’ll be making the most of each and
                every session. Your circumstances and your individual needs will
                inform your 3-month programme.
              </p>
              <p>
                With a commitment of just three months, you’ll be on the pathway
                to success.
              </p>
              <p>
                Up to 4 hours support per month. Two meetings per month.
                Telephone and email support between meetings.
              </p>
              <p>Skype or Zoom for clients anywhere in the UK or Worldwide.</p>
              <p>Face-to-face available in East Sussex.</p>
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col>
              <Button
                variant="outline-primary"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Get in Touch
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <Row>
            <Col>
              <h2>Option 4</h2>
              <h3>
                6 Month Coaching Programme{" "}
                <span className="text-secondary">£2340</span>
              </h3>
              <hr className="bg-secondary w-25 float-left" />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                My tailor-made 6-month Business or Executive Coaching programmes
                suit almost every small business owner or senior decision-maker,
                regardless of organisation or business type.
              </p>
              <p>
                We’ll be making a commitment to work together over a six months
                period to make positive change. You’ll benefit from contact and
                support between meetings and being held accountable for staying
                focussed and carrying out agreed actions. This is an ideal way
                not just to make a plan for change but to work together to make
                it happen.
              </p>
              <p>
                Whether you have a trade or professional services business or
                you’re coming to me for executive coaching, my services will be
                tailored exactly to you. You’ll be making the most of each and
                every session. Your circumstances and your individual needs will
                inform your 6-month programme.
              </p>
              <p>
                With a commitment to six months, we’ll achieve a great deal.
              </p>
              <p>
                Up to 4 hours support per month. Two meetings per month.
                Telephone and email support between meetings.
              </p>
              <p>Skype or Zoom for clients anywhere in the UK or Worldwide.</p>
              <p>Face-to-face available in East Sussex.</p>
            </Col>
          </Row>
          <Row className="text-center mt-3 ">
            <Col>
              <Button
                variant="outline-primary"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Get in Touch
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <Row>
            <Col>
              <h2>Option 5</h2>
              <h3>
                12 Months VIP Coaching Programme{" "}
                <span className="text-secondary">£4680</span>
              </h3>
              <hr className="bg-secondary w-25 float-left" />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                My tailor-made VIP 12-month Business or Executive Coaching is
                ideal if you’d like to create a vision for what things will look
                like in your small business, career or life in 12 months and are
                absolutely serious about making those things happen within that
                time frame. This time next year ….
              </p>
              <p>
                The advantages of taking out a VIP 12 months package are that
                you are making a serious commitment to work with me over a one
                year period to make positive change.
              </p>
              <p>
                You’ll benefit from contact and support between meetings as
                required and being held accountable for staying focussed and
                carrying out agreed actions. This is an ideal way not just to
                make a plan for change but to stick at it for the whole year,
                working together to make your vision a reality.
              </p>
              <p>
                Whether you have a trade or professional services business or
                coming to me for executive coaching, my services will be
                tailored exactly to you. You’ll be making the most of each and
                every session. Your 12-month programme will be designed
                according to your unique individual circumstances and needs and
                how you’d like to work with me.
              </p>
              <p>
                With a commitment of twelve months, let’s decide your goals and
                make them happen.
              </p>
              <p>
                Up to 4 hours support per month excluding my travelling time.
                Meeting times can include early mornings or Saturday’s if
                required. For local clients - I’ll travel to you anywhere in
                East or West Sussex for one meeting per month. If you are based
                elsewhere in London and the South East, I’ll travel to you for a
                face to face meeting once every three months if required. Our
                other meetings will be over Skype or Zoom or face to face near
                Eastbourne. Telephone and email support between meetings.
              </p>
              <p>
                Skype or Zoom meetings for clients anywhere in the UK or
                Worldwide.
              </p>
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col>
              <Button
                variant="outline-primary"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Get in Touch
              </Button>
            </Col>
          </Row>
        </Container>
      </section> */}
			<section className="py-5 bg-secondary text-white">
				<Container>
					<Row>
						<Col>
							<h2>First Step - Discovery meeting</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								The first step is for us to have a discovery meeting. It will be
								great to meet you and listen about your circumstances, what
								you’d like to achieve and discuss how I can help. I won’t sell
								to you. Instead, I will give you feedback and suggest a way
								forward that I think would be most helpful to you. Please get in
								touch via the contact page, and we’ll get a day and time into
								our diaries.
							</p>
						</Col>
					</Row>
					<Row className="text-center mt-3">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Book Your Discovery Meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default PackagesPage;
